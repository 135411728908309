<template>
    <div class="asn-preview">
        <div class="asn-preview-fields">
            <div class="asn-preview-field">Number: </div>
            <div class="asn-preview-value"><input type="text" :value="asnModel.number" @blur="onAsnNumberBlur"/></div>
            <div class="asn-preview-field">Vendor: </div>
            <div class="asn-preview-value">{{asnModel.vendor}}</div>
        </div>            
        <h4>Containers:</h4>
        <div class="asn-preview-containers">
            <ul>
                <li v-for="(tare, index) in tareStructure" :key="index">
                    <div class="asn-preview-containers-tare">{{tare.index}}</div>
                    <ul>
                        <li v-for="(pack, packIndex) in tare.packs" :key="packIndex">
                            <div class="asn-preview-containers-pack">{{pack.index}}</div>
                            <ul>
                                <li class="asn-preview-containers-line" 
                                v-for="(line, lineIndex) in pack.lines" :key="lineIndex">
                                    <div><strong>PO #</strong> {{line.shipToPONumber }} - <strong>Line #</strong> {{line.lineNumber}} - <strong>Organization</strong> {{line.shipToPOOrganization}}</div>
                                    <div><strong>Item</strong> {{line.itemCode}} - <strong>Quantity</strong> {{line.packedQuantity}}</div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.asn-preview {
}

.asn-preview h4 { 
    text-align: left;
}

.asn-preview-fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.asn-preview-field {
    width: 45%;
    text-align: left;
    font-weight: bold;
    padding: 0.2em;
}

.asn-preview-value {
    width: 45%;
    text-align: left;
}

.asn-preview-containers {
    display: flex;
    flex-direction: column;
    border: solid black 1px;
}

.asn-preview-containers-tare {
    font-weight: bold;
}

.asn-preview-containers-pack {
    font-weight: bold;
}

.asn-preview-containers-line {
    padding: 0.2em;
}

.asn-preview-container-fields {
    display: flex;
    flex-wrap: wrap;
    padding: 0.3em;
    text-align: left;
}

.asn-preview-container-field {
    width: 20%;
}

.asn-preview-container-field-value {
    width: 70%;
}

.asn-preview-containers li {
    text-align: left;
}

</style>

<script lang="js" src="./AsnPreview.js" />