import { mapGetters, mapActions } from "vuex";

export default {
    name: "PurchaseOrders",
    data() {
        return {
            alert: {
                message: "",
                clearLines: false,
                vendor: {}
            },
            showAlertModal: false,   
            showVendorList: true         
        }
    },
    computed: {
        ...mapGetters('purchaseOrders', {
            purchaseOrders: 'getPurchaseOrders', 
            selectedPurchaseOrders: 'getSelectedPurchaseOrderIds',
            status: 'getStatus'
        }),
        ...mapGetters('asnGen', {
            vendors: 'getVendors',
            asnModel: 'getAsnModel',
            vendorStatus: 'getStatus'
        }),
        currentVendor: function() {
            return this.vendors.find(vendor => vendor.code === this.currentAsnVendor)
        },
        currentAsnVendor: function() {
            return this.asnModel.vendor
        }        
    },
    mounted: function() {
        this.loadVendors();
    },
    methods: {
        ...mapActions('purchaseOrders', ['loadPurchaseOrders', 'loadVendorPurchaseOrders', 'toggleSelectedPurchaseOrder', 'deSelectPurchaseOrders']),
        ...mapActions('asnGen', ['loadVendors', 'setAsnVendor', 'setAsnToInformation', 'clearAsnModelLines']),
        isPoSelected: function(po) {
            return this.selectedPurchaseOrders.includes(po.data.id)
        },
        selectVendor(vendor, force=false) {
            if (!force && vendor.code !== this.currentAsnVendor && this.asnModel.lines.length > 0) {
                this.showAlertModal = true
                this.alert.clearLines = true
                this.alert.vendor = vendor
                this.alert.message = "Changing vendors will erase all existing ASN lines!"
                return
            }
            this.setAsnVendor(vendor.code)
            this.showVendorList = false
            this.loadVendorPurchaseOrders(vendor.code)
        },
        selectPurchaseOrder(po) {
            this.deSelectPurchaseOrders()
            this.toggleSelectedPurchaseOrder(po)
            this.setAsnToInformation(po.data.to)
        },
        confirm() {
            this.clearAsnModelLines().then(() => {
                this.deSelectPurchaseOrders()
                this.selectVendor(this.alert.vendor, true)
            })  
            this.showAlertModal = false
        },
        closeAllModals() {
            this.showAlertModal = false
        }
    }
}