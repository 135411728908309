export default {
    name: "ContainerOptions",
    props: [
        "options"        
    ],
    computed: {
        numberOfContainers: {
            get: function() {
                return this.options.packs.length;
            }
        },
        numberOfPallets: {
            get: function() {
                return this.options.tares.length;
            }
        },
    },    
    methods: {
        clickAddButton() {
            this.$emit('addButtonClicked')
        }
    }
}