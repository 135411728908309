export default {
    name: "AsnContainerDetails",
    props: [
        "containertypes",
    ],
    data() {
        return {
            containerType: "",
            containerId: ""
        }
    },
    watch: {
        containertypes: {
            immediate: true,
            handler (newVal) {
                this.containerType = newVal[0].name
            }
        } 
    },
    methods: {
        emitUpdate() {
            const container = {id: this.containerId, type: this.containerType}
            this.$emit('onAsnContainerConfirm', container)
        }
    }
    
}