import AsnGenOptions from '../components/AsnGenOptions.vue'
import PurchaseOrders from '../components/PurchaseOrders.vue'
import AsnPreview from '../components/AsnPreview.vue'

export default {
  name: 'AsnGenerator',
  components: {
      AsnGenOptions,
      PurchaseOrders,
      AsnPreview
  }
}