import { mapGetters, mapActions } from "vuex"

export default {
    name: "AsnPreview",
    data() {
        return {
            tareStructure : {}
        }
    },
    computed: {
        ...mapGetters('asnGen', { asnModel: 'getAsnModel'}),
        
    },
    watch: {
        asnModel: {
            deep: true,
            handler: function() {
                this.refreshtareStructure()
            }
        }
    },
    mounted: function() {
        this.refreshtareStructure()
    },
    methods: {
        ...mapActions('asnGen', ['setAsnNumber']),
        refreshtareStructure: function() {
            const noTare = "No Tare"
            const tares = [{index: noTare, packs:[]}]
            
            for (var ctr=0; ctr < this.asnModel.tares.length; ctr++) {
                tares.push({index: this.asnModel.tares[ctr].index, packs:[]})
            }

            for (var i=0; i < tares.length; i++) {
                var linesForTare = []
                if (tares[i].index === noTare)
                    linesForTare = this.asnModel.lines.filter(line => line.tare.index.length === 0)
                else    
                    linesForTare = this.asnModel.lines.filter(line => line.tare.index === tares[i].index)

                for(var j=0; j < linesForTare.length; j++) {
                    //add pack if it doesn't exist yet
                    if (!tares[i].packs.find(pack => {return pack.index == linesForTare[j].pack.index})) 
                        tares[i].packs.push({index: linesForTare[j].pack.index, lines:[]})

                    //add line to pack level
                    tares[i].packs.find(pack => {return pack.index == linesForTare[j].pack.index}).lines.push(linesForTare[j])
                }
            }
            this.tareStructure = tares
        },
        onAsnNumberBlur(event) {
            this.setAsnNumber(event.target.value)
        }
    }
}