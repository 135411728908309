export default {
    name: "PurchaseOrder",
    props: [
        'purchaseOrder',
        'lineButtonText'
    ],
    methods: {
        clickPoLine(poId, poExtRef, poOrg, poLine) {
            this.$emit('lineButtonClicked', {id: poId, organization: poOrg, externalRef: poExtRef, lineItem: poLine})
        }
    }
}