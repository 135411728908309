<template>
    <div class="asn-generator">
        <div class="asn-generator-content">
            <div class="asn-generator-content-pos">
                <h3>Select Vendor and Purchase Orders:</h3>
                <purchase-orders/>
            </div>
            <div class="asn-generator-content-asngenoptions">
                <h3>Add Containers and PO Lines:</h3>
                <asn-gen-options/>
            </div>
            <div class="asn-generator-content-asnpreview">
                <h3>Asn Preview:</h3>
                <asn-preview />
            </div>
        </div>
    </div>
</template>

<style scoped>
.asn-generator-content {
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.asn-generator-content-pos {
    border:black dotted 1px;
    padding: 0.4em;
    width: 25%;
}

.asn-generator-content-asngenoptions {
    border:black dotted 1px;
    padding: 0.4em;
    width: 40%;
}

.asn-generator-content-asnpreview {
    border:black dotted 1px;
    padding: 0.4em;
    width: 35%;
}
</style>

<script lang="js" src="./AsnGenerator.js" />