<template>
    <div class="purchase-orders">
        <div class="purchase-orders-content">
            <div v-if="vendorStatus.loading">{{vendorStatus.message}}</div>
            <div v-if="vendorStatus.error" class="purchase-orders-error">{{vendorStatus.message}}</div>            
            <div class="purchase-orders-vendor-selected" v-if="currentVendor">
                <div @click="showVendorList = !showVendorList">
                    {{currentVendor.code}} - {{currentVendor.name}}
                </div>
            </div>
            <div v-if="status.loading">{{status.message}}</div>                        
            <ul v-if="showVendorList">
                <li class="purchase-orders-headers">
                    <div>Vendors</div>
                </li>                
                <li v-for="(vendor, index) in vendors" :key="index"
                    @click="selectVendor(vendor)"
                    :class="[ (currentAsnVendor === vendor.code) ? 'purchase-orders-selected' : '']">
                    <div class="purchase-orders-vendor-code">{{vendor.code}}</div>
                    <div class="purchase-orders-vendor-name">{{vendor.name}}</div>
                </li>
            </ul>
            <ul>
                <li class="purchase-orders-headers">
                    <div class="purchase-orders-index">#</div>
                    <div class="purchase-orders-number">P.O. Number</div>
                </li>
                <li v-for="(purchaseOrder, index) in purchaseOrders" :key="purchaseOrder.data.id"
                    @click="selectPurchaseOrder(purchaseOrder)"
                    :class="[ (isPoSelected(purchaseOrder)) ? 'purchase-orders-selected' : '']">
                    <div class="purchase-orders-index">{{index}}</div>
                    <div class="purchase-orders-number">{{purchaseOrder.data.number}}</div>
                </li>
            </ul>
        </div>
        <div class="purchase-orders-overlay" v-if="showAlertModal" @click="closeAllModals"></div>
        <div class="purchase-orders-modal" v-if="showAlertModal">
            <div class="purchase-orders-modal-content">
                {{alert.message}}
            </div>
            <div class="purchase-orders-modal-footer">
                <button @click="closeAllModals">Cancel</button>
                <button @click="confirm">Ok</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.purchase-orders-content {
    display: flex;
    flex-direction: column;
}
.purchase-orders-content > ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
}

.purchase-orders-content li {
    cursor: pointer;
    padding: 0.5em;
    display: flex;
    flex-direction: row;
    border: black 1px solid;
}

.purchase-orders-content div {
    margin: 0.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.purchase-orders-vendor-selected div{
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    color: #42b983;
    word-wrap: break-word;
    font-weight: bold;
    cursor: pointer;
    border: black 1px solid;
    white-space: normal;
    text-align: left;
}

.purchase-orders-vendor-code {
    width: 30%;
}

.purchase-orders-vendor-name {
    width: 60%;
}

.purchase-orders-headers {
    font-weight: bold;
}

.purchase-orders-selected {
    color: #42b983;
    font-weight: bold;
}

.purchase-orders-error {
    color: red;
    font-weight: bold;
}

.purchase-orders-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .5);
}

.purchase-orders-modal {
    position: fixed;
    width: 30%;
    top:50px;
    z-index: 9999;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.purchase-orders-modal-footer {
    display: flex;
    flex-direction: row-reverse;
}

.purchase-orders-modal-footer button {
    width: 5em;
    padding: 0.5em;
    margin: 0.3em;
}
</style>

<script lang='js' src="./PurchaseOrders.js" />