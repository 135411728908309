import { mapActions, mapGetters } from "vuex"
import PurchaseOrder from "./PurchaseOrder.vue"
import ContainerOptions from "./ContainerOptions.vue"
import AsnLineDetails from "./AsnLineDetails.vue"
import AsnContainerDetails from "./AsnContainerDetails.vue"

export default {
    name: "AsnGenOptions",
    components: {
        PurchaseOrder,
        ContainerOptions,
        AsnLineDetails,
        AsnContainerDetails,
    },
    data() {
        return {
            showAsnLineDetails: false,
            showAsnContainerDetails: false,
            showNewAsnConfirmation: false,
            showCdmModal: false,
            selectedPoLineDetails: {},
            generateAsn:false
        }
    },
    computed: {
        ...mapGetters('purchaseOrders', { selectedPurchaseOrders: 'getSelectedPurchaseOrders' }),
        ...mapGetters('asnGen', { 
            addToAsnStatus: 'getAddStatus',
            loadAsnCdmStatus: 'getLoadAsnCdmStatus',
            purchaseOrders: 'getPurchaseOrders', 
            containerOptions: 'getContainerOptions',
            containerTypes: 'getContainerTypes',
            asnCdmModelResponse: 'getAsnCdmModelResponse',
            asnModel: 'getAsnModel'
        }),
        showOptionsModal: function() {
            return this.showAsnContainerDetails || this.showAsnLineDetails || this.showNewAsnConfirmation
        }
    },
    watch: {
        selectedPurchaseOrders: function(newVal) {
            //we remove all other pos to restrict the asn to 1 PO at a time to simplify asn generation
            this.removeAllPurchaseOrders();//TODO: remove when we implement PO multi-selection
            
            if (newVal.length > 0) {
                let newPO = newVal[newVal.length-1]
                if (newPO) {
                    if (!this.purchaseOrders.find(po => po.PurchaseOrderId === newPO.data.id))
                        this.loadPurchaseOrder(newPO.data.id)
                }
            }
        }
    },
    methods: {
        ...mapActions('asnGen', [
            'loadPurchaseOrder', 
            'removeAllPurchaseOrders', 
            'addAsnContainer', 
            'addAsnLine',
            'loadAsnCdmModelResponse',
            'resetAddStatus',
            'setAsnNumber',
            'clearAsnModelLines'
        ]),
        addContainerRequested() {
            this.resetAddStatus()
            this.showAsnContainerDetails = true
            this.showAsnLineDetails = false
            this.showNewAsnConfirmation = false
        },
        addAsnLineRequested(poLineDetails) {
            this.resetAddStatus()
            this.showAsnLineDetails = true
            this.showAsnContainerDetails = false
            this.showNewAsnConfirmation = false
            this.selectedPoLineDetails = poLineDetails
        },
        newAsnRequested(){
            this.resetAddStatus()
            this.showAsnLineDetails = false
            this.showAsnContainerDetails = false
            this.showNewAsnConfirmation = true
        },
        closeOptionsModal() {
            this.showAsnContainerDetails = false
            this.showAsnLineDetails = false
            this.showNewAsnConfirmation = false
        },
        closeAllModals() {
            this.showAsnContainerDetails = false
            this.showAsnLineDetails = false
            this.showCdmModal = false
            this.showNewAsnConfirmation = false
        },
        confirmOptionsModal() {
            if (this.showAsnContainerDetails)
                this.$refs.asnConDetailsRef.emitUpdate()
            else if (this.showAsnLineDetails)
                this.$refs.asnLineDetailsRef.emitUpdate()
            else if (this.showNewAsnConfirmation)
                this.clearAsnModel()
        },
        addNewContainer(container) {
            this.addAsnContainer(container).then(() =>{
                if (!this.addToAsnStatus.error)
                    this.showAsnContainerDetails = false
            })            
        },
        addNewLine(line) {
            this.addAsnLine(line).then(() => {
                if (!this.addToAsnStatus.error)
                    this.showAsnLineDetails = false
            })
        },
        confirmContainerDetails(containerDetails) {
            this.addNewContainer(containerDetails)
        },
        confirmLineDetails(lineDetails) {
            this.addNewLine(lineDetails)
        },
        showAsnCdm() {
            this.showCdmModal = true
            this.loadAsnCdmModelResponse(this.generateAsn)
        },
        clearAsnModel() {
            //deep watch on asnModel lines is not working. 
            //Need this workaround to reload after clearing in case the ASN Number was empty to start with 
            //so it will detect the change 
            this.setAsnNumber(" ") 
            //________
            this.setAsnNumber("")
            this.clearAsnModelLines()
            this.showNewAsnConfirmation = false
        }
    }

}