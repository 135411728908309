<template>
    <div class="container-options">
        <button @click="clickAddButton">+</button>
        <div class="container-options-fields">
            <div class="container-options-field">Number of containers:</div>
            <div class="container-options-field">{{numberOfContainers}}</div>
            <div class="container-options-field">Number of pallets:</div>
            <div class="container-options-field">{{numberOfPallets}}</div>
        </div>        
    </div>
</template>

<style scoped>
    .container-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .container-options button {
        margin: 0.3em;
        padding: 0.5em;
        width: 3em;        
    }

    .container-options-fields {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        padding: 0.2em;
    }

    .container-options-field {
        text-align: left;
        width: 40%;
        padding: 0.2em;
        font-weight: bold;
    }

    .container-options-field input {
        text-align: right;
    }
</style>

<script lang='js' src="./ContainerOptions.js" />