<template>
    <div class="po">
        <div class="po-headers">
            <div class="po-header-field">Vendor:</div>            
            <div class="po-header-value">{{purchaseOrder.VendorCode}}</div>
            <div class="po-header-field">PO Id:</div>
            <div class="po-header-value">{{purchaseOrder.PurchaseOrderId}}</div>
            <div class="po-header-field">PO Number:</div>
            <div class="po-header-value">{{purchaseOrder.PurchaseOrderNumber}}</div>
            <div class="po-header-field">External Reference:</div>
            <div class="po-header-value">{{purchaseOrder.ExternalReference}}</div>
        </div>
        <div class="po-lines">
            <div class="po-header-field">PO Lines:</div>
            <ul>
                <li class="po-lines-line" v-for="lineItem in purchaseOrder.LineItem" :key="lineItem.LineNumber">
                    <div>{{lineItem.LineNumber}} - Item Code: {{lineItem.ItemCode}} - {{lineItem.ExpectedQuantity}}</div>
                    <button @click="clickPoLine(purchaseOrder.PurchaseOrderNumber, purchaseOrder.ExternalReference, purchaseOrder.OrganizationCode, lineItem)">{{lineButtonText}}</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

    .po {
        display: flex;
        flex-direction: column;
    }
    
    .po-headers {
        display: flex;
        flex-wrap: wrap;        
    }    

    .po-header-field {
        flex-grow: 1;
        overflow: hidden;
        width: 25%;
        text-align: left;
        font-weight: bold;
        padding: 0.2em;
    }
    
    .po-header-value {
        flex-grow: 1;
        overflow: hidden;
        width: 70%;
        text-align: left;
        padding: 0.2em;
    }

    .po-lines {
        padding-top: 0.5em;
        text-align: left;
    }

    .po-lines ul {
        border: black 1px solid;
        padding: 0.5em;
    }

    .po-lines-line {
        display: flex;
        justify-content: space-between;
        list-style: none;
        text-align: left;
        padding: 0.3em;
    }

    .po-lines-line div {
        width: 70%;
        text-align: left;
    }

    .po-lines-line button {
        width: 5%;
        padding: 0.3em;
    }

    .po-2cells > .po-header-field { width: 50%; }
</style>

<script lang='js' src="./PurchaseOrder.js" />