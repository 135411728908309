<template>
    <div class="asn-gen-options">
        <div class="asn-gen-options-commands">            
            <button @click="newAsnRequested">New ASN</button>
            <button @click="showAsnCdm(asnModel)">Load CDM Model</button>
        </div>
        <div class="asn-gen-options-toggles">
            <input type="checkbox" v-model="generateAsn" id="chk"/><label for="chk">Generate Asn</label>
        </div>
        <h3>Containers</h3>        
        <container-options :options="containerOptions" @addButtonClicked="addContainerRequested"/>
        <h3>Purchase Order</h3>
        <purchase-order 
            class="asn-gen-options-po"
            v-if="purchaseOrders && purchaseOrders.length>0" 
            :purchaseOrder="purchaseOrders[0]" 
            lineButtonText="+"
            @lineButtonClicked="addAsnLineRequested"/>
        
        <div class="asn-gen-options-overlay" v-if="showOptionsModal || showCdmModal" @click="closeAllModals"></div>
        <div class="asn-gen-options-modal" v-if="showCdmModal">
            <div class="asn-gen-options-modal-error" v-if="loadAsnCdmStatus.error">
                {{loadAsnCdmStatus.message}}
            </div>
            {{asnCdmModelResponse}}
            <div class="asn-gen-options-modal-footer">
                <button @click="closeAllModals">Ok</button>
            </div>
        </div>
        <div class="asn-gen-options-modal" v-if="showOptionsModal">
            <div class="asn-gen-options-modal-error" v-if="addToAsnStatus.error">
                {{addToAsnStatus.message}}
            </div>
            <asn-container-details ref="asnConDetailsRef" v-if="showAsnContainerDetails"
                :containertypes="containerTypes"
                @onAsnContainerConfirm="confirmContainerDetails" />
            <asn-line-details ref="asnLineDetailsRef" v-if="showAsnLineDetails"
                :polinedetails="selectedPoLineDetails" :containeroptions="containerOptions" 
                @onAsnLineDetailsConfirm="confirmLineDetails"/>
            <div class="asn-gen-options-modal-message" v-if="showNewAsnConfirmation">
                This operation will clear the data of the current ASN. Do you wish to proceed?
            </div>
            <div class="asn-gen-options-modal-footer">
                <button @click="closeOptionsModal">Cancel</button>
                <button @click="confirmOptionsModal">Ok</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .asn-gen-options {
        display: flex;
        flex-direction: column;
    }

    .asn-gen-options-commands {
        display: flex;
        flex-direction: row;
        padding: 0.3em;
    }

    .asn-gen-options-commands button {
        padding: 0.3em;
        margin: 0.3em;
        width: 10em;
    }

    .asn-gen-options-toggles {
        padding: 0.3em;
        align-self: start;
    }

    .asn-gen-options h3 {
        text-align: left;
    }
    
    .asn-gen-options-po {
        padding: 0.3em;
    }

    .asn-gen-options-overlay {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, .5);
    }

    .asn-gen-options-modal {
        position: fixed;
        width: 50%;
        z-index: 9999;
        margin: 0 auto;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .asn-gen-options-modal-message {
        padding: 0.5em;
        margin: 0.3em;
    }

    .asn-gen-options-modal-error {
        color: red;
    }

    .asn-gen-options-modal-footer {
        display: flex;
        flex-direction: row-reverse;
    }

    .asn-gen-options-modal-footer button {
        width: 5em;
        padding: 0.5em;
        margin: 0.3em;
    }
</style>

<script lang="js" src="./AsnGenOptions.js"/>