export default {
    name: "AsnLineDetails",
    props: [
        "polinedetails",
        "containeroptions"
    ],
    data() {
        return {
            selectedPackId: "",
            selectedTareId: "",
            quantityToAdd: 0
        }
    },
    computed: {
        poNumber: function() {
            return this.polinedetails.id
        },
        poOrganization: function() {
            return this.polinedetails.organization
        },
        poExternalRef: function() {
            return this.polinedetails.externalRef
        },
        poLine: function() {
            return this.polinedetails.lineItem.ShiptoCustomerPurchaseOrderNumber ?
                this.polinedetails.lineItem.ShiptoCustomerPurchaseOrderNumber 
                : this.polinedetails.lineItem.LineNumber
        },
        itemCode: function() {
            return this.polinedetails.lineItem.ItemCode
        },
        vendorItemCode: function() {
            return this.polinedetails.lineItem.VendorItemCode
        },
        orderedQuantity: function() {
            return this.polinedetails.lineItem.OrderedQuantity ? this.polinedetails.lineItem.OrderedQuantity : 0 
        },
        packs: function() {
            return this.containeroptions.packs
        },
        tares: function() {
            return this.containeroptions.tares
        }
    },
    methods: {
        emitUpdate() {
            const line = {
                packId: this.selectedPackId, 
                tareId: this.selectedTareId, 
                itemCode: this.itemCode, 
                vendorItemCode: this.vendorItemCode,
                poNumber: this.poNumber, 
                poLineNumber: this.poLine,
                poExternalRef: this.poExternalRef, 
                poOrganization: this.poOrganization,
                packedQuantity: this.quantityToAdd
            }
            this.$emit('onAsnLineDetailsConfirm', line)
        }
    }
}