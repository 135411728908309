<template>
    <div class="container-add">
        <h3>ASN Container Details</h3>
        <div class="container-add-fields">
            <div class="container-add-field">Container Type:</div>
            <div class="container-add-field">                
                <select v-model="containerType">
                    <option v-for="(containerType, index) in containertypes" :key="index">{{containerType.name}}</option>
                </select>
            </div>
            <div class="container-add-field">Container Id:</div>
            <div class="container-add-field"><input type="text" v-model="containerId" /></div>            
        </div>
    </div>
</template>

<style scoped>
.container-add-fields {
    display: flex;
    flex-wrap: wrap;
}

.container-add-field {
    text-align: left;
    width: 40%;
    padding: 0.5em;
}

.container-add-field input, select {
    width: 90%;
}
</style>

<script lang="js" src="./AsnContainerDetails.js" />