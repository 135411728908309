<template>
    <div>
        <h3>ASN Line Details</h3>
        <div class="asn-line-details">
            <div class="asn-line-details-field">PO Id:</div>
            <div class="asn-line-details-field">{{poNumber}}</div>
            <div class="asn-line-details-field">PO Line:</div>
            <div class="asn-line-details-field">{{poLine}}</div>
            <div class="asn-line-details-field">Tare:</div>
            <div class="asn-line-details-field">
                <select v-model="selectedTareId">
                    <option v-for="(tare, index) in tares" :key="index">{{tare.id}}</option>
                </select>
            </div>
            <div class="asn-line-details-field">Pack:</div>
            <div class="asn-line-details-field">
                <select v-model="selectedPackId">
                    <option v-for="(pack, index) in packs" :key="index">{{pack.id}}</option>
                </select>
            </div>
            <div class="asn-line-details-field">Item</div>
            <div class="asn-line-details-field">{{itemCode}}</div>
            <div class="asn-line-details-field">Ordered Quantity</div>
            <div class="asn-line-details-field">{{orderedQuantity}}</div>
            <div class="asn-line-details-field">Packed Quantity</div>
            <div class="asn-line-details-field">N/A</div>
            <div class="asn-line-details-field">Quantity to add:</div>
            <div class="asn-line-details-field">
                <input type="text" v-model="quantityToAdd">
            </div>
        </div>
    </div>
</template>

<style scoped>
.asn-line-details {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.asn-line-details-field {
    width: 40%;
    text-align: left;
    padding: 0.3em;
    /* border: 1px black solid; */
}

.asn-line-details-field select, input {
    width: 50%;
    padding: 0.2em;
}
</style>

<script lang="js" src="./AsnLineDetails.js" />